import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addNewBorderAlert } from "../../redux";
import { setupAlertWebSocket } from "../../utils/websocket";
import { calculateTravelerStats } from "../../hooks/calculateTravelerStats";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import BorderAlerts from "../../components/alerts/BorderAlerts";

const TravelersStatisticsPage = () => {
  const dispatch = useDispatch();
  const transactionsData = useSelector(
    (state) => state.transactions.transactionsData
  );
  const stats = calculateTravelerStats(transactionsData);

  const borderAlertsState = useSelector((state) => state.borderAlerts.alerts);
  const [borderAlerts, setBorderAlerts] = useState(borderAlertsState);

  useEffect(() => {
    setBorderAlerts(borderAlertsState);
  }, [borderAlertsState]);

  // Fetch vehicles and setup WebSocket connections on mount
  useEffect(() => {
    // Set up WebSocket for alerts
    const cleanupAlertWebSocket = setupAlertWebSocket((newAlertData) => {
      dispatch(addNewBorderAlert(newAlertData));
    });

    // Cleanup function for both WebSockets
    return () => {
      cleanupAlertWebSocket();
    };
  }, [dispatch]);

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: "0 auto" }}>
      {borderAlerts.length >= 1 && <BorderAlerts alerts={borderAlerts} />}

      <Typography variant="h4" gutterBottom>
        Travellers Analysis
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* Total Transactions Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Total Transactions
              </Typography>
              <Typography variant="h3" color="primary">
                {stats.totalTransactions}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Unique Travelers Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Unique Travellers
              </Typography>
              <Typography variant="h3" color="primary">
                {stats.uniqueTravelers}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(stats.uniqueTravelers / stats.totalTransactions) * 100}
                sx={{ mt: 2 }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Travelers with Multiple Transactions */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary">
                Travellers with Multiple Transactions
              </Typography>
              <Typography variant="h3" color="primary">
                {stats.travelersWithMultipleTransactions}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Status Distribution Card */}
      <Card>
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            Transaction Status Distribution
          </Typography>
          <Box sx={{ mt: 2 }}>
            {Object.entries(stats.statusCounts).map(([status, count]) => (
              <Box key={status} sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography variant="body2">
                    {status.replace(/_/g, " ")}
                  </Typography>
                  <Typography variant="body2">
                    {count} (
                    {((count / stats.totalTransactions) * 100).toFixed(1)}
                    %)
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={(count / stats.totalTransactions) * 100}
                />
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>

      {/* Detailed Status Table */}
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            Travellers Frequency
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Traveler ID</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Distribution (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stats.top20Travelers.map(
                  ({ travelerId, count, percentage }) => (
                    <TableRow key={travelerId}>
                      <TableCell>{travelerId}</TableCell>
                      <TableCell align="right">{count}</TableCell>
                      <TableCell align="right">{percentage}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TravelersStatisticsPage;
