import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Typography,
  Fab,
  Box,
  Chip,
  Paper,
  Stack,
  DialogContent,
  Dialog,
} from "@mui/material";
import {
  DirectionsCar,
  Group,
  Schedule,
  Assignment,
  Camera as CameraIcon,
  KeyboardArrowUp,
} from "@mui/icons-material";
import PassengersDisplay from "./PassengersDisplay";
import XRayScansCard from "./XRayScansCard";

const ConfidenceValue = ({ value }) => (
  <Chip
    label={`${Math.round(value * 100)}%`}
    variant="outlined"
    size="small"
    sx={{
      bgcolor: "white",
      fontWeight: 500,
      fontSize: "0.875rem",
    }}
  />
);

const InfoCard = ({ title, icon, children, action }) => (
  <Card sx={{ height: "100%" }}>
    <CardHeader
      avatar={
        <Box
          sx={{
            bgcolor: "primary.main",
            borderRadius: 1,
            p: 1,
            color: "white",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
      }
      action={action}
      title={<Typography variant="h6">{title}</Typography>}
    />
    <CardContent>{children}</CardContent>
  </Card>
);

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <Fab
        color="primary"
        size="small"
        onClick={scrollToTop}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <KeyboardArrowUp />
      </Fab>
    )
  );
};

const TransactionDisplay = ({ data, handleUpdate, isLoading }) => {
  const { plateRecognition, carTransaction, passengers, carScans } = data;
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Info display handler
  const handleShowInfo = (uuid) => {
    const url = `/decision/${uuid}`;
    window.location.href = url; // Opens the URL in the same tab
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Transaction Status */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
                  {plateRecognition.licensePlate}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Schedule sx={{ color: "text.secondary" }} />
                  <Typography variant="body2" color="text.secondary">
                    {new Date(plateRecognition.datetime).toLocaleString()}
                  </Typography>
                </Stack>
              </Box>
              <Chip
                label={carTransaction.transactionStatus}
                sx={{ fontSize: "1.1rem", px: 2 }}
              />
            </Box>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                align="left"
                sx={{ mb: 2 }}
              >
                Update Status
              </Typography>
              <Grid container spacing={2}>
                {[
                  {
                    status: "ONGOING",
                    color: "primary",
                  },
                  {
                    status: "DECISION_PENDING",
                    color: "primary",
                  },
                  {
                    status: "CLOSED",
                    color: "error",
                  },
                ]
                  .filter(
                    ({ status }) => status !== carTransaction.transactionStatus
                  )
                  .map(({ status, color }) => (
                    <Grid item xs={12} sm={3} md={3} key={status}>
                      <Button
                        variant="contained"
                        color={color}
                        onClick={() => handleUpdate(status)}
                        disabled={isLoading}
                        fullWidth
                        sx={{ height: 40 }}
                      >
                        {status.replace(/_/g, " ").toUpperCase()}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Vehicle Details */}
        <Grid item xs={12}>
          <InfoCard title="Vehicle Details" icon={<DirectionsCar />}>
            <Stack spacing={3}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Type
                  </Typography>
                  <ConfidenceValue
                    value={parseFloat(plateRecognition.typeConfidence)}
                  />
                </Box>
                <Chip
                  label={plateRecognition.carType}
                  color="primary"
                  sx={{ mb: 1 }}
                />
                {/* <Typography variant="body2" color="text.secondary">
                  {plateRecognition.typeReasoning}
                </Typography> */}
                {/* <Paper variant="outlined" sx={{ p: 2, bgcolor: "grey.50" }}> */}
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: "grey.50",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {plateRecognition.typeReasoning
                    .trim()
                    .replace(/\/\/n/g, "/n")
                    .replace("/n", "")
                    .split("-")
                    .map((reason, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={{
                          mb: 1,
                          fontWeight: index !== 0 ? "text.secondary" : "bold",
                        }}
                      >
                        {index !== 0 && reason[0] === reason[0].toUpperCase()
                          ? "- " + reason.trim()
                          : reason.trim()}
                      </Typography>
                    ))}
                </Paper>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Color
                  </Typography>
                  <ConfidenceValue
                    value={parseFloat(plateRecognition.colorConfidence)}
                  />
                </Box>
                <Chip
                  label={plateRecognition.carColor}
                  color="primary"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" color="text.secondary">
                  {plateRecognition.colorReasoning}
                </Typography>
              </Box>
            </Stack>
          </InfoCard>
        </Grid>

        {/* X-RAY SCAN */}
        <Grid item xs={12}>
          <XRayScansCard carScans={carScans} />
        </Grid>

        {/* License Plate Recognition */}
        <Grid item xs={12}>
          <InfoCard title="License Plate Recognition" icon={<CameraIcon />}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    aspectRatio: "16/9",
                    overflow: "hidden",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                  onClick={() =>
                    handleImageClick(
                      `data:image/png;base64,${plateRecognition.xaiLicensePlateImage}`
                    )
                  }
                >
                  <img
                    src={`data:image/png;base64,${plateRecognition.xaiLicensePlateImage}`}
                    alt="License Plate XAI"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      {plateRecognition.licensePlate}
                    </Typography>
                    <ConfidenceValue
                      value={parseFloat(
                        plateRecognition.licensePlateConfidence
                      )}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>

        {/* Passenger Details */}
        <Grid item xs={12}>
          <InfoCard title="Passenger Detection" icon={<Group />}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    aspectRatio: "16/9",
                    overflow: "hidden",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                  onClick={() =>
                    handleImageClick(
                      `data:image/png;base64,${plateRecognition.xaiPeopleCountingImage}`
                    )
                  }
                >
                  <img
                    src={`data:image/png;base64,${plateRecognition.xaiPeopleCountingImage}`}
                    alt="Passenger Detection XAI"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="h3" textAlign="center" sx={{ mb: 2 }}>
                      {plateRecognition.peopleCounting}
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                      Passengers Detected
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mb: 2 }}
                    >
                      Detection Confidence
                    </Typography>
                    {plateRecognition.peopleCountingConfidence.map(
                      (confidence, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">
                            Passenger {index + 1}
                          </Typography>
                          <ConfidenceValue value={parseFloat(confidence)} />
                        </Box>
                      )
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>

        {/* Registered Passengers */}
        <Grid item xs={12}>
          <InfoCard
            title="Registered Passengers"
            icon={<Assignment />}
            action={
              <Chip
                label={`Total Registered: ${passengers.length}`}
                color="primary"
                variant="outlined"
              />
            }
          >
            <PassengersDisplay
              passengers={passengers}
              handleShowInfo={handleShowInfo}
            />
          </InfoCard>
        </Grid>
      </Grid>

      {/* Back to Top Button */}
      <ScrollToTopButton />

      {/* Modal for Enlarged Image */}
      <Dialog
        open={Boolean(selectedImage)}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Enlarged View"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "90vw",
                maxHeight: "90vh",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TransactionDisplay;
