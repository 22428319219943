class TextConstants {
  // API related constants
  static API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  static VEHICLES_WEBSOCKET_URL = process.env.REACT_APP_VEHICLES_WEBSOCKET_URL;
  static TRAVELERS_WEBSOCKET_URL =
    process.env.REACT_APP_TRAVELERS_WEBSOCKET_URL;
  static ALERTS_WEBSOCKET_URL = process.env.REACT_APP_ALERTS_WEBSOCKET_URL;
  static CROWD_COUNTING_WEBSOCKET_URL =
    process.env.REACT_APP_CROWD_COUNTING_1_WEBSOCKET_URL;

  // Endpoint related constants
  static ALL_VEHICLES_ENDPOINT = `${TextConstants.API_BASE_URL}/vehicles/all`;
  static VEHICLES_ENDPOINT = `${TextConstants.API_BASE_URL}/vehicles/`;
  static VEHICLES_VERIFICATION_ENDPOINT = `${TextConstants.API_BASE_URL}/vehicles/verify`;

  static ALL_TRAVELERS_ENDPOINT = `${TextConstants.API_BASE_URL}/travelers/all`;
  static TRAVELERS_ENDPOINT = `${TextConstants.API_BASE_URL}/travelers/`;
  static TRAVELERS_VERIFICATION_ENDPOINT = `${TextConstants.API_BASE_URL}/travelers/verify`;

  static ALL_TRANSACTIONS_ENDPOINT = `${TextConstants.API_BASE_URL}/transactions/all`;
  static TRANSACTIONS_ENDPOINT = `${TextConstants.API_BASE_URL}/transactions/`;
  static ALL_CAR_TRANSACTIONS_ENDPOINT = `${TextConstants.API_BASE_URL}/transactions/car/all`;
  static CAR_TRANSACTIONS_ENDPOINT = `${TextConstants.API_BASE_URL}/transactions/car`;

  // Routes
  static TRAVELER_VERIFICATION_ROUTE = "travel-verification/";
  static TRANSACTIONS_DECISION_ROUTE = "decision/";
  static TRANSACTIONS_CAR_DECISION_ROUTE = "car-decision/";

  // Headers
  static REAL_TIME_VEHICLES_HEADER = "Vehicles Data View";
  static REAL_TIME_VEHICLES_SUBTITLE =
    "Live updates of vehicle data across the network";
  static REAL_TIME_TAVELERS_HEADER = "Travellers Data View";
  static REAL_TIME_TRAVELERS_SUBTITLE =
    "Live updates of travellers data across the network";
  static REAL_TIME_TRANSACTIONS_HEADER = "Transactions Data View";
  static REAL_TIME_TRANSACTIONS_SUBTITLE =
    "Live updates of transactions data across the network";
  static ABOUT_HEADER = "About the ODYSSEUS DSS";

  // Titles
  static APP_TITLE = "Odysseus DSS";

  // Button labels
  static DASHBOARD_BUTTON = "Dashboard";
  static VEHICLES_BUTTON = "Vehicles";
  static STATISTICS_BUTTON = "Statistics";
  static TRAVELERS_BUTTON = "Travellers";
  static ABOUT_BUTTON = "About";
  static PROFILE_BUTTON = "Profile";
  static SIGN_IN_BUTTON = "Sign In";
  static FORGOT_PASSWORD_BUTTON = "Forgot password?";
  static LOGOUT_BUTTON = "Logout";

  // Tooltips
  static VERIFIED = "Verified";
  static PENDING_VERIFICATION = "Pending";
  static DELETE_VEHICLE = "Delete Vehicle";
  static DELETE_TRAVELER = "Delete Traveler";
  static VIEW_DETAILS = "View Details";
  static VERIFY_VEHICLE = "Verify Vehicle";
  static VERIFY_TRAVELER = "Verify Traveler";
}

export default TextConstants;
