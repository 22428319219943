import React, { useState } from "react";
import { Box, Paper, Container, Tabs, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import { Commute, SwapHoriz } from "@mui/icons-material";
import { clearSelectedVehicle } from "../../redux";
import TransactionsDashboard from "./transactions/TransactionsDashboard";
import CarTransactionsDashboard from "./car_transactions/CarTransactionsDashboard";
import DeviceInfoDisplay from "../../components/alerts/DeviceInfoDisplay";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderContent = () => {
    if (value === 0) {
      return <CarTransactionsDashboard />;
    } else {
      dispatch(clearSelectedVehicle()); // Avoid scrolling when go back to the CarTransactionsDashboard
      return <TransactionsDashboard />;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <DeviceInfoDisplay />

        <Paper sx={{ mb: 1, mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab icon={<Commute />} label="Vehicles" />
            {/* <Tab icon={<People />} label="Travelers" /> */}
            <Tab icon={<SwapHoriz />} label="Travellers" />
          </Tabs>
        </Paper>
        {renderContent()}
      </Container>
    </Box>
  );
};

export default Dashboard;
