import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  fetchAccessToken,
  logout,
  clearBorderAlerts,
  clearCrowdCountingAlerts,
  clearCarTransactions,
  clearTransactions,
} from "./redux";
import { NavBar } from "./components/navbar/NavBar";
import DashBoard from "./pages/dashboard/DashBoard";
import SignIn from "./pages/auth/SignIn";
import { Box } from "@mui/material";
import Profile from "./pages/auth/Profile";
import StatisticsDashboard from "./pages/statistics/StatisticsDashboard";
import TransactionWithDetails from "./pages/dashboard/transactions/components/TransactionWithDetails";
import CarTransactionWithDetails from "./pages/dashboard/car_transactions/components/CarTransactionWithDetails";
import TextConstants from "./utils/constants/TextConstants";
import TableSkeleton from "./components/skeletons/TableSkeleton";
import { useMediaQuery } from "@mui/material";

function App() {
  const isMobile = useMediaQuery("(max-width:600px)"); // Detects mobile screens
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.user.info);
  const username =
    localStorage.getItem("username") ||
    sessionStorage.getItem("username") ||
    null;
  const password =
    localStorage.getItem("username") ||
    sessionStorage.getItem("username") ||
    null;

  // Check local storage for user and password when the app loads
  useEffect(() => {
    const storedUsername =
      localStorage.getItem("username") || sessionStorage.getItem("username");
    const storedPassword =
      localStorage.getItem("password") || sessionStorage.getItem("password");
    if (storedUsername && storedPassword) {
      dispatch(
        fetchAccessToken({ username: storedUsername, password: storedPassword })
      )
        .then((result) => {
          if (result.payload) {
            const token = result.payload;
            dispatch(fetchUser(token));
            console.log("Logged in with stored creds");
          } else {
            console.error("Failed to fetch access token or user info");
          }
        })
        .catch((error) => {
          console.error("Error fetching access token or user info", error);
        });
    }
  }, [dispatch]);

  // Logout function using Redux dispatch
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearBorderAlerts());
    dispatch(clearCrowdCountingAlerts());
    dispatch(clearCarTransactions());
    dispatch(clearTransactions());
    localStorage.removeItem("username");
    sessionStorage.removeItem("username");
    localStorage.removeItem("password");
    sessionStorage.removeItem("password");
  };

  return (
    <Router>
      <div>
        {/* Conditionally render the SignIn page or the rest of the app */}
        {userInfo ? (
          <>
            {/* Navigation Bar with onLogout prop */}
            <NavBar onLogout={handleLogout} />

            {/* Routes */}
            <Box sx={{ mt: 2, px: isMobile ? 0 : 10 }}>
              <Routes>
                <Route path="/" element={<DashBoard />} />
                <Route path="/statistics" element={<StatisticsDashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path={`${TextConstants.TRANSACTIONS_DECISION_ROUTE}:uuid`}
                  element={<TransactionWithDetails />}
                />
                <Route
                  path={`${TextConstants.TRANSACTIONS_CAR_DECISION_ROUTE}:uuid`}
                  element={<CarTransactionWithDetails />}
                />
              </Routes>
            </Box>
          </>
        ) : username && password && userInfo === null ? (
          <>
            <NavBar onLogout={handleLogout} />
            <Box sx={{ mt: 2, px: isMobile ? 0 : 10 }}>
              <TableSkeleton />
            </Box>
          </>
        ) : (
          <SignIn />
        )}
      </div>
    </Router>
  );
}

export default App;
