import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  Biotech as BiotechIcon,
  ZoomIn,
  Close,
  RadioButtonChecked,
  AutoFixHigh,
  FilterVintage,
} from "@mui/icons-material";

// Improved X-Ray scan component
const XRayScansCard = ({ carScans }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageType, setSelectedImageType] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleImageClick = (image, type) => {
    setSelectedImage(image);
    setSelectedImageType(type);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedImageType("");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // X-Ray scan images with their types
  const scanImages = [
    {
      type: "HEMD",
      image: `data:image/png;base64,${carScans?.hemdImage}`,
      icon: <RadioButtonChecked />,
      description: "High-Energy Material Detection",
    },
    {
      type: "Backscatter",
      image: `data:image/png;base64,${carScans?.backscatterXrayImage}`,
      icon: <AutoFixHigh />,
      description: "Surface Detection X-Ray",
    },
    {
      type: "Transmission",
      image: `data:image/png;base64,${carScans?.transmissionXrayImage}`,
      icon: <FilterVintage />,
      description: "Full Penetration X-Ray",
    },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Box
            sx={{
              bgcolor: "primary.main",
              borderRadius: 1,
              p: 1,
              color: "white",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiotechIcon />
          </Box>
        }
        title={<Typography variant="h6">X-RAY Scans</Typography>}
      />
      <CardContent>
        <Grid container spacing={3}>
          {/* Vehicle scan information */}
          <Grid item xs={12}>
            <Paper
              elevation={2}
              sx={{
                p: 2,
                mb: 2,
                bgcolor: "background.paper",
                borderLeft: 4,
                borderColor: "primary.main",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Front Plate:
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {carScans.frontPlateNumber || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Rear Plate:
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {carScans.rearPlateNumber || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Passengers:
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {carScans.numberOfPassangers || "0"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* X-Ray images navigation */}
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                {scanImages.map((scan, index) => (
                  <Tab
                    key={index}
                    icon={scan.icon}
                    label={scan.type}
                    iconPosition="start"
                  />
                ))}
              </Tabs>
            </Box>
          </Grid>

          {/* X-Ray image display */}
          <Grid item xs={12}>
            <Box sx={{ position: "relative" }}>
              <Paper
                sx={{
                  p: 1,
                  boxShadow: 3,
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.01)",
                    boxShadow: 6,
                  },
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      aspectRatio: "16/9",
                      overflow: "hidden",
                      borderRadius: 1,
                      bgcolor: "black",
                    }}
                  >
                    <img
                      src={scanImages[tabValue].image}
                      alt={`${scanImages[tabValue].type} Scan`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>

                  <Tooltip
                    title="Zoom Image"
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="zoom"
                      onClick={() =>
                        handleImageClick(
                          scanImages[tabValue].image,
                          scanImages[tabValue].type
                        )
                      }
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        bgcolor: "rgba(0, 0, 0, 0.6)",
                        color: "white",
                        "&:hover": {
                          bgcolor: "rgba(0, 0, 0, 0.8)",
                        },
                      }}
                    >
                      <ZoomIn />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {scanImages[tabValue].description}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Modal for Enlarged Image */}
        <Dialog
          open={Boolean(selectedImage)}
          onClose={handleCloseModal}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            sx={{
              bgcolor: "primary.dark",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{selectedImageType} X-Ray Scan</Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{ color: "white" }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              bgcolor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Enlarged View"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "90vh",
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default XRayScansCard;
