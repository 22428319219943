export const calculateTravelerStats = (data) => {
  const totalTransactions = data.length;

  // Calculate status counts and percentages
  const statusCounts = data.reduce((acc, item) => {
    acc[item.transactionStatus] = (acc[item.transactionStatus] || 0) + 1;
    return acc;
  }, {});

  // Calculate traveler statistics
  const travelerCounts = {};
  data.forEach((item) => {
    travelerCounts[item.travelerId] =
      (travelerCounts[item.travelerId] || 0) + 1;
  });

  const uniqueTravelers = Object.keys(travelerCounts).length;
  const travelersWithMultipleTransactions = Object.values(
    travelerCounts
  ).filter((count) => count > 1).length;

  // Get Top 20 Travelers
  const top20Travelers = Object.entries(travelerCounts)
    .sort((a, b) => b[1] - a[1]) // Sort by transaction count (descending)
    .slice(0, 20) // Get top 20
    .map(([travelerId, count]) => ({
      travelerId,
      count,
      percentage: ((count / totalTransactions) * 100).toFixed(2) + "%",
    }));

  return {
    totalTransactions,
    statusCounts,
    uniqueTravelers,
    travelersWithMultipleTransactions,
    top20Travelers,
  };
};
